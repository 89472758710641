.Caption {
    background: linear-gradient( #FBF7D6 0%, #D1843D 100% );
    text-align: center;
    color: white;
    font-family: 'Open Sans', 'Arial', Arial, sans-serif;
    font-weight:bold;
    margin-top: 0px;
    width: 100vw;
    height: 145px;
}

.Caption div {
    margin:0px;
}

.countrytitle1 {
    font-size: 42px;
    margin: 0px;
    color:darkslategrey;
}

.countrytitle2 {
    font-size: 24px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:italic;
    margin: 0px;
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: darkslategrey;
}

.countrylinks {
    display:inline-block;
    margin-top: 16px;
    padding: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

a {
    background-color: #44445044;
    border: 2px solid #38423655;
    padding: 8px;
    color: aliceblue;
}

a:hover {
    background-color: #55565044;
    border: 2px solid #48524655;
    padding: 8px;
    color: white;
}
