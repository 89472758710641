
.App {
}

.pagebackground {
    width: 70%;
    display: inline-block;
    flex-flow: column;
    align-items: center;
    margin-left: 15%;
    margin-right: 5%;
    margin-top:1.5%;
    background-color: #292121;
    border: 1px solid #342365AA;
    border-top: 4px solid black;
    padding: 8px;
    color: aliceblue;
}

.App-header {
    background: linear-gradient( #313150, #313150 13%, #101020 100% );
    min-height: 100px;
    vertical-align: middle;
    font-size: 34px;
    color: white;
    padding-left: 16px;
    text-align: left;
    font-family: 'Open Sans', 'Arial', Arial, sans-serif
}

H1 {
    margin: 0px;
    margin-bottom: 14px;
    font-size: 24pt;
    font-family: 'Open Sans', 'Arial', Arial, sans-serif;
    border-bottom: 1px double #313150;
    font-weight: normal;
}

H2 {
    margin: 0px;
    font-family: 'Open Sans', 'Arial', Arial, sans-serif;
    font-weight: normal;
    color:#F5E9C3;
    padding:0px;
}

p {
    margin-bottom:2.0em;
}

.signature {
    margin: 0px;
    font-size: 28pt;
    font-family: 'Magneto Bold', 'Brush Script MT', Cambria, Cochin, Georgia, Times, Times New Roman, serif
}

.background-bottom {
    width: 100vw;
    height: 100vh;
    overflow:clip;
}

.topspace {
    margin-top: 50px;
}